import SwiperSlider from 'integration-web-core--socle/js/assets/modules/_swiperSlider.class';
class ServiceStoreServices extends lm.Composant {
  constructor(id) {
    super(id);
  }
}
lm.DOMReady(function () {
  try {
    new ServiceStoreServices('servicestoreservices');
  } catch (error) {
    throw new Error(`Error : ${error}`);
  }
  try {
    const sliderHome = document.querySelector('.m-slider-essentials');
    if (sliderHome) {
      new SwiperSlider('.m-slider-essentials');
    }
  } catch (error) {
    throw new Error(`Error : ${error}`);
  }
});
export default ServiceStoreServices;